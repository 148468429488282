'use strict'
import DtoUserLight from '../../online/account/dto/DtoUserLight'
import DtoCMSEvent from '../../online/cms/dto/DtoCMSEvent'
import {
    RECEIVE_DATE_VALID_CGU,
    RECEIVE_CGU_CMS,
    RECEIVE_APPLICATION_SETTINGS,
    RECEIVE_ALL_USERS_LIGHT,
} from '../constants/HomeConstants'

export const store = {
    applicationSettings: [],
    dateValidCgu: '',
    usersLight: [],
    cmsCGU: [], // // à passer dans le CmsReducer
}

export function HomeReducer(state = {}, action) {
    switch (action.type) {
        case RECEIVE_APPLICATION_SETTINGS:
            return {
                ...state,
                applicationSettings: action.applicationSettings,
            }
        case RECEIVE_DATE_VALID_CGU:
            return {
                ...state,
                dateValidCgu: action.date,
            }
        case RECEIVE_ALL_USERS_LIGHT:
            return {
                ...state,
                usersLight: (action.users || []).map((u) => new DtoUserLight(u)),
            }
        case RECEIVE_CGU_CMS: // // à passer dans le CmsReducer
            return {
                ...state,
                cmsCGU: action.cmsCGU.map((c) => new DtoCMSEvent(c)),
            }
        default:
            return state
    }
}
