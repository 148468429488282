'use strict'
import { push } from '@lagunovsky/redux-react-router'
import fetch from 'isomorphic-fetch'
import i18n from 'simple-react-i18n'
import {
    FULL_RESET,
    MEDEAU_TOKEN,
    MEDEAU_LOGIN,
    RECEIVE_DATE_VALID_CGU,
    RECEIVE_APPLICATION_SETTINGS,
    RECEIVE_ALL_USERS_LIGHT,
    CREDENTIALS,
    RECEIVE_CGU_CMS,
    MODULE,
} from '../constants/HomeConstants'
import ApplicationConf from '../../../conf/ApplicationConf'
import {
    checkAuth,
    getAuthorization,
    removeToken,
    getJson,
    checkError,
    getAuthorizationLogin,
    checkAuthV2,
    aquaFetchV2,
} from '../../../utils/ActionUtils'
import ToastrAction from '../../online/components/toasters/ToastrAction'
import LogAction from '../../../utils/log/actions/LogAction'

const HomeAction = {
    login(login, password) {
        const basicAuth = btoa(`${login}:${password}`)
        return () => {
            return fetch(ApplicationConf.login(), {
                method: 'POST',
                headers: {
                    Authorization: `Basic ${basicAuth}`,
                    Module: MODULE,
                    Platform: 'WEB',
                },
                body: JSON.stringify({
                    module: MODULE,
                })
            })
                .then(response => checkAuthV2(response, {
                    401: () => {
                        ToastrAction.error(i18n.incorrectIdOrPassword, true)
                        return false
                    },
                }))
                .then(getJson)
                .then((json) => {
                    localStorage.setItem(MEDEAU_LOGIN, login)
                    localStorage.setItem(CREDENTIALS, basicAuth)
                    localStorage.setItem(MEDEAU_TOKEN, json)
                })
                .catch(e => {
                    LogAction.logError(`${i18n.fetchError + i18n.connexion} : ${e}`)
                    ToastrAction.error(i18n.fetchError + i18n.connexion)
                })
        }
    },

    logout() {
        return (dispatch) => {
            return fetch(ApplicationConf.logout(), {
                method: 'POST',
                headers: getAuthorization(),
            }).then((response) => {
                removeToken()
                dispatch(push('/login')).then(() => {
                    dispatch(HomeAction.fullReset())
                })
                if (response.status !== 200) {
                    throw response.statusText
                }
            })
        }
    },

    resetPassword(login, redirectLogin = true) {
        return (dispatch) => {
            return fetch(ApplicationConf.resetPassword(), {
                method: 'POST',
                body: JSON.stringify({ login, module: 'MOBILE' }),
            })
                .then(checkError)
                .then((json) => {
                    if (json.status !== 200) {
                        ToastrAction.error(i18n.incorrectId, true)
                        return json
                    }
                    if (redirectLogin) {
                        dispatch(push('/login'))
                    }
                    return null
                })
                .catch((e) => {
                    LogAction.logError(`${i18n.fetchError + i18n.retrievePassword} : ${e}`)
                    ToastrAction.error(i18n.fetchError + i18n.retrievePassword, true)
                })
        }
    },

    updatePassword(password, token) {
        return () => {
            return fetch(ApplicationConf.resetPassword(), {
                method: 'PUT',
                body: JSON.stringify({ password, token })
            })
                .then(checkError)
                .catch(e => {
                    LogAction.logError(`${i18n.fetchError + i18n.newPassword} : ${e}`)
                })
        }
    },

    verifyResetCode(login, resetCode) {
        return () => {
            return fetch(ApplicationConf.verifyResetCode(), {
                method: 'POST',
                body: JSON.stringify({ login, resetCode })
            })
                .then(response => checkAuthV2(response, {
                    404: () => {
                        ToastrAction.error(i18n.unableToResetPassword)
                        return false
                    },
                    429: () => {
                        ToastrAction.error(i18n.error429)
                        return false
                    },
                    200: () => {
                        ToastrAction.success(i18n.valideCode)
                        return true
                    }
                }))
                .then(response => {
                    return response
                })
                .catch(e => {
                    LogAction.logError(`${i18n.fetchError + i18n.retrievePassword} : ${e}`)
                    ToastrAction.error(i18n.fetchError + i18n.retrievePassword)
                })
        }
    },

    receiveUsersLight(users) {
        return { type: RECEIVE_ALL_USERS_LIGHT, users }
    },

    promiseUsersLight() {
        return fetch(ApplicationConf.user.usersLight(), {
            method: 'GET',
            headers: getAuthorization(),
        })
            .then(checkAuth)
            .then(checkError)
    },
    fetchUsersLight() {
        return (dispatch) => {
            return HomeAction.promiseUsersLight()
                .then(json => {
                    dispatch(HomeAction.receiveUsersLight(json))
                })
        }
    },

    fullReset() {
        return (dispatch) => {
            dispatch({ type: FULL_RESET })
        }
    },

    getDateValidCGU(login) {
        return (dispatch) => {
            return fetch(ApplicationConf.cgu.getDateValidCGU(login), {
                method: 'GET',
                headers: getAuthorization(),
            })
                .then(getJson)
                .then((json) => {
                    dispatch(HomeAction.receiveDateValidCGU(json.date))
                })
                .catch((err) => {
                    LogAction.logError(`${i18n.fetchError} : ${err}`)
                })
        }
    },

    sendDateCgu(postDateCGU) {
        return () => {
            return fetch(ApplicationConf.cgu.sendDateCgu(), {
                method: 'POST',
                headers: getAuthorization(),
                body: JSON.stringify(postDateCGU),
            }).catch((err) => {
                LogAction.logError(`${i18n.createError} : ${err}`)
            })
        }
    },

    receiveDateValidCGU(date) {
        return { type: RECEIVE_DATE_VALID_CGU, date }
    },

    receiveCGUFromCms(cmsCGU) { // // à passer dans CmsAction
        return { type: RECEIVE_CGU_CMS, cmsCGU }
    },

    getCGUFromCms() { // // à passer dans CmsAction
        return (dispatch) => {
            return fetch(ApplicationConf.cms.getByCategory(3), {
                method: 'GET',
                headers: getAuthorizationLogin(),
            })
                .then(getJson)
                .then((json) => {
                    dispatch(HomeAction.receiveCGUFromCms(json))
                })
                .catch((err) => {
                    LogAction.logError(`${i18n.fetchError} : ${err}`)
                })
        }
    },

    receiveApplicationSettings(applicationSettings) {
        return { type: RECEIVE_APPLICATION_SETTINGS, applicationSettings }
    },

    fetchApplicationSettings() {
        return (dispatch, getState) => {
            return aquaFetchV2(ApplicationConf.user.applicationSettings(), {
                method: 'GET',
                headers: getAuthorizationLogin()
            }, { defaultResult: getState().HomeReducer.applicationSettings })
                .then(json => {
                    dispatch(HomeAction.receiveApplicationSettings(json))
                })
                .catch((err) => {
                    LogAction.logError(`${i18n.fetchError + i18n.settings} : ${err}`)
                    ToastrAction.error(i18n.fetchError + i18n.settings)
                })
        }
    },

    checkUrl: (url, callback = () => {}) => async () => {
        return await fetch(url, { method: 'HEAD' })
            .then(res => {
                callback(res.ok)
                return res.ok
            })
            .catch(() => {
                callback(false)
                return false
            })
    }
}

export default HomeAction
