import { Grid2 } from '@mui/material'
import React, { useMemo } from 'react'
import DtoWaterTurn from '../../agri/dto/DtoWaterTurn'
import { getDarkerColor } from '../../../../utils/ColorUtil'
import { arrayOf, instanceOf } from '../../../../utils/StoreUtils'
import DtoWaterTurnsSlot from '../../referencials/dto/DtoWaterTurnsSlot'
import DtoWaterTurnRestriction from '../../referencials/dto/DtoWaterTurnRestriction'

const WaterTurnsWeekCalendar = ({
    waterTurn = {},
    waterTurnsSlots = [],
    waterTurnsRestrictions = [],
}) => {
    const allWTRestrictions = [
        ...waterTurnsRestrictions,
        {
            id: 'X',
            color: '#52D100',
            level: 'X',
            label: 'OK',
        }
    ]
    const slot = waterTurnsSlots.find((s) => s.id === waterTurn.idSlot) || {}
    const plages = slot.slots?.split('/') ?? []
    const dureePlage = 24 / slot.nbSlots

    const getNbOccurences = (day, level, startIndex, nbOccurences = 1) => {
        if (day[startIndex] === level) {
            return getNbOccurences(day, level, startIndex + 1, nbOccurences + 1)
        }
        return nbOccurences
    }

    const actualDay = useMemo(() => new Date().getDay(), [])
    const actualHour = useMemo(() => new Date().getHours(), [])

    const getPlageItem = (dayValue, level, index, day) => {
        if (level === dayValue[index - 1]) {
            return null
        }
        const plage = plages[index]
        const [start, end] = plage.split('-')
        const nbOccurences = getNbOccurences(dayValue, level, index + 1, 1)
        const restr = allWTRestrictions.find((r) => `${r.level}` === level)
        const darkerColor = getDarkerColor(restr.color)
        return (
            <Grid2
                className='bold'
                sx={{
                    border: (actualDay === day && actualHour >= Number(start) && actualHour < Number(end)) ? `2px solid ${darkerColor}` : '2px solid white',
                    borderRadius: 2,
                    backgroundColor: restr.color,
                    height: `${dureePlage*10*nbOccurences}px`,
                    color: 'white',
                    padding: '10px 3px',
                    fontSize: '10px',
                    textAlign: 'center',
                    wordBreak: 'break-all',
                    opacity: day < actualDay || (actualDay === day && actualHour > Number(start) && actualHour >= Number(end)) ? 0.3 : 1,
                }}
            >
                {restr.label}
            </Grid2>
        )
    }

    return (
        <Grid2 container alignItems='flex-start' justifyContent='center' sx={{ padding: '5px' }}>
            <Grid2 size={12} className='bold'>{waterTurn.material}</Grid2>
            <Grid2 container size={12/8} sx={{ paddingTop: '26px' }} direction='column' alignItems='center' justifyContent='center' spacing={3.2}>
                <Grid2 sx={{ }}>00:00</Grid2>
                <Grid2 sx={{ }}>04:00</Grid2>
                <Grid2 sx={{ }}>08:00</Grid2>
                <Grid2 sx={{ }}>12:00</Grid2>
                <Grid2 sx={{ }}>16:00</Grid2>
                <Grid2 sx={{ }}>20:00</Grid2>
            </Grid2>
            <Grid2 size={12/8} container direction='column' sx={{ padding: '5px 0' }}>
                <Grid2 sx={{ height: '10px', textAlign: 'center', marginBottom: '10px' }}>L</Grid2>
                {waterTurn.day1.split('').map((level, i) => getPlageItem(waterTurn.day1, level, i, 1))}
            </Grid2>
            <Grid2 size={12/8} container direction='column' sx={{ padding: '5px 0' }}>
                <Grid2 sx={{ height: '10px', textAlign: 'center', marginBottom: '10px' }}>M</Grid2>
                {waterTurn.day2.split('').map((level, i) => getPlageItem(waterTurn.day2, level, i, 2))}
                {/* {actualDay === 2 && (
                    <div style={{ position: 'absolute', width: '45px', height: '240px', marginTop: '20px' }}>
                        <div style={{ backgroundColor: 'black', marginTop: `${actualHour * 10}px`, height: '2px' }} />
                    </div>
                )} */}
            </Grid2>
            <Grid2 size={12/8} container direction='column' sx={{ padding: '5px 0' }}>
                <Grid2 sx={{ height: '10px', textAlign: 'center', marginBottom: '10px' }}>M</Grid2>
                {waterTurn.day3.split('').map((level, i) => getPlageItem(waterTurn.day3, level, i, 3))}
            </Grid2>
            <Grid2 size={12/8} container direction='column' sx={{ padding: '5px 0' }}>
                <Grid2 sx={{ height: '10px', textAlign: 'center', marginBottom: '10px' }}>J</Grid2>
                {waterTurn.day4.split('').map((level, i) => getPlageItem(waterTurn.day4, level, i, 4))}
            </Grid2>
            <Grid2 size={12/8} container direction='column' sx={{ padding: '5px 0' }}>
                <Grid2 sx={{ height: '10px', textAlign: 'center', marginBottom: '10px' }}>V</Grid2>
                {waterTurn.day5.split('').map((level, i) => getPlageItem(waterTurn.day5, level, i, 5))}
            </Grid2>
            <Grid2 size={12/8} container direction='column' sx={{ padding: '5px 0' }}>
                <Grid2 sx={{ height: '10px', textAlign: 'center', marginBottom: '10px' }}>S</Grid2>
                {waterTurn.day6.split('').map((level, i) => getPlageItem(waterTurn.day6, level, i, 6))}
            </Grid2>
            <Grid2 size={12/8} container direction='column' sx={{ padding: '5px 0' }}>
                <Grid2 sx={{ height: '10px', textAlign: 'center', marginBottom: '10px' }}>D</Grid2>
                {waterTurn.day7.split('').map((level, i) => getPlageItem(waterTurn.day7, level, i, 7))}
            </Grid2>
        </Grid2>
    )
}

WaterTurnsWeekCalendar.propTypes = {
    waterTurn: instanceOf(DtoWaterTurn),
    waterTurnsSlots: arrayOf(DtoWaterTurnsSlot),
    waterTurnsRestrictions: arrayOf(DtoWaterTurnRestriction),
}

export default WaterTurnsWeekCalendar