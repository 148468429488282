'use strict'
import fetch from 'isomorphic-fetch'
import i18n from 'simple-react-i18n'
import {
    RECEIVE_MESSAGES_OF,
    RECEIVE_CONTACTS,
} from '../constants/ContactConstants'
import ApplicationConf from '../../../../conf/ApplicationConf'
import { checkAuth, getAuthorization, aquaFetchV2 } from '../../../../utils/ActionUtils'
import ToastrAction from '../../components/toasters/ToastrAction'
import LogAction from '../../../../utils/log/actions/LogAction'


const ContactAction = {
    receiveContacts(contacts) {
        return { type: RECEIVE_CONTACTS, contacts }
    },
    fetchContacts() {
        return (dispatch, getState) => {
            return aquaFetchV2(ApplicationConf.referencial.contacts(), {
                method: 'GET',
                headers: getAuthorization(),
            }, { defaultResult: getState().ContactReducer.contacts })
                .then((json = []) => {
                    dispatch(ContactAction.receiveContacts(json))
                })
                .catch((err) => {
                    LogAction.logError(`${i18n.fetchError + i18n.contacts} : ${err}`)
                    ToastrAction.error(i18n.fetchError + i18n.contacts)
                })
        }
    },

    receiveMessagesOf(listOfMessages) {
        return { type: RECEIVE_MESSAGES_OF, listOfMessages }
    },
    getMessagesOf(login) {
        return (dispatch) => {
            return fetch(ApplicationConf.contact.getMessagesOf(login), {
                method: 'GET',
                headers: getAuthorization(),
            })
                .then(checkAuth)
                .then((json) => {
                    dispatch(ContactAction.receiveMessagesOf(json))
                })
                .catch((err) => {
                    LogAction.logError(`${i18n.fetchError} : ${err}`)
                })
        }
    },

    sendMessage(userMessage) {
        return () => {
            return fetch(ApplicationConf.contact.sendMessage(), {
                method: 'POST',
                headers: getAuthorization(),
                body: JSON.stringify(userMessage),
            })
                .then(checkAuth)
                .then((json) => {
                    if (json.id) {
                        return json.id
                    }
                    throw new Error()
                })
                .catch((err) => {
                    LogAction.logError(`${i18n.createError} : ${err}`)
                })
        }
    },
}

export default ContactAction
