export default class DtoInstallationGeo {
    constructor(obj) {
        this.id = obj.id // Option[Int],
        this.code = obj.code // Option[String],
        this.name = obj.name // Option[String],
        this.townCode = obj.townCode // Option[String],
        this.address = obj.address // Option[String],
        this.updateLogin = obj.updateLogin // Option[String],
        this.updateDate = obj.updateDate // Option[DateTime],
        this.stateCode = obj.stateCode // Option[Int],
        this.ownerCode = obj.ownerCode // Option[Int],
        this.managementCode = obj.managementCode // Option[Long],
        this.subManagementCode = obj.subManagementCode // Option[Long],
        this.location = obj.location // Option[String],
        this.x = obj.x // Option[Double],
        this.y = obj.y // Option[Double],
        this.projection = obj.projection // Option[Double],

        this.parcel = obj.parcel // Option[String] = None,
        this.section = obj.section // Option[String] = None,
        this.sampleType = obj.sampleType // Option[Int] = None,
        this.bvCode = obj.bvCode // Option[Int] = None,
        this.droughtSector = obj.droughtSector // Option[Int] = None,
        this.installationType = obj.installationType // Option[Int] = None
        this.annualWeeklyVolume = obj.annualWeeklyVolume // Option[Long] = None
    }
}