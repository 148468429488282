import React, { useEffect, useMemo, useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import i18n from 'simple-react-i18n'
import { push } from '@lagunovsky/redux-react-router'
import { Grid2, useTheme } from '@mui/material'
import HomeAction from '../../offline/actions/HomeAction'
import { getLogin } from '../../../utils/UserUtils'
import ToastrAction from '../components/toasters/ToastrAction'
import { MainButton, TextButton } from '../components/styled/buttons'
import { isValidEmail, isValidPhone } from '../../../utils/FormUtils'
import AccountAction from './actions/AccountAction'
import { mainColor, textColor } from '../components/styled/theme'
import { getDate } from '../../../utils/DateUtil'
import { ArrowForwardIos, EditOutlined, FolderOutlined } from '@mui/icons-material'
import { LightCard } from '../components/styled/grid'
import { InputRow } from '../components/styled/inputs'
import LoadingCard from '../components/cards/LoadingCard'
import ModalUpdatePasswordDesktop from './components/ModalUpdatePasswordDesktop'
import { formatPhone } from '../../../utils/StringUtil'

const MyAccountDesktop = () => {
    const [openModal, setOpenModal] = useState(false)
    const [name, setName] = useState('')
    const [phone, setPhone] = useState('')
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [newPassword, setNewPassword] = useState('')
    const [newPasswordConfirmation, setNewPasswordConfirmation] = useState('')
    const [editMode, setEditMode] = useState('')

    const isUnvalidEmail = useMemo(() => editMode && email.length > 0 && !isValidEmail(email), [email])
    const isUnvalidPhone = useMemo(() => editMode && phone.length > 0 && !isValidPhone(phone), [phone])

    const {
        accountUser,
        accountContact,
        dateValidCgu,
        applicationSettings,
    } = useSelector(store => ({
        accountUser: store.AccountReducer.accountUser,
        accountContact: store.AccountReducer.contact,
        dateValidCgu: store.HomeReducer.dateValidCgu,
        applicationSettings: store.HomeReducer.applicationSettings,
    }), shallowEqual)

    const dispatch = useDispatch()

    const theme = useTheme()

    /* const { progress, isLoaded } = useProgressDispatch(() => {
        const contactPromise = accountUser?.contactCode ? [
            dispatch(AccountAction.fetchAccountContact(accountUser.contactCode)).then(res => {
                setName(res.name || '')
                setPhone(res.phoneTel || '')
                setEmail(res.email || '')
            })
        ] : [
            dispatch(AccountAction.fetchUser(getLogin())).then((account) => {
                dispatch(AccountAction.fetchAccountContact(account.contactCode)).then(res => {
                    setName(res.name || '')
                    setPhone(res.phoneTel || '')
                    setEmail(res.email || '')
                })
            })
        ]

        return [
            ...contactPromise,
            dispatch(HomeAction.getDateValidCGU(getLogin())),
        ]
    }, []) */

    const [isLoaded, setIsLoaded] = useState(false)

    const getContactAndCgu = (account = accountUser) => {
        dispatch(AccountAction.fetchAccountContact(account.contactCode)).then(res => {
            setName(res.name || '')
            setPhone(res.phoneTel || '')
            setEmail(res.email || '')
        }).then(() => {
            dispatch(HomeAction.getDateValidCGU(getLogin())).then(() => setIsLoaded(true))
        })
    }

    useEffect(() => {
        if (!accountUser?.contactCode) {
            dispatch(AccountAction.fetchUser(getLogin()))
                .then((account) => {
                    getContactAndCgu(account)
                })
        } else {
            getContactAndCgu()
        }
    }, [accountUser])

    const toggleModal = () => {
        setOpenModal(!openModal)
    }

    const onSavePassword = () => {
        const login = getLogin()
        if (password) {
            dispatch(HomeAction.login(login, password)).then(() => {
                if (
                    newPassword !== null &&
                    newPassword.length &&
                    newPasswordConfirmation !== null &&
                    newPasswordConfirmation.length
                ) {
                    if (newPassword === newPasswordConfirmation) {
                        const regex = RegExp(applicationSettings.find((s) => s.parameter === 'passwordPolicy').value)
                        const regexHelp = applicationSettings.find((s) => s.parameter === 'securityPasswordDescription').value
                        if (regex.test(newPassword)) {
                            if (newPassword !== login) {
                                dispatch(AccountAction.changePassword({ password: btoa(decodeURIComponent(encodeURIComponent(newPassword))) }))
                                toggleModal()
                            } else {
                                ToastrAction.error(i18n.passwordNeedToBeDifferent, true)
                            }
                        } else {
                            ToastrAction.error(regexHelp, true)
                        }
                    } else {
                        ToastrAction.error(i18n.passwordAreNotTheSame, true)
                    }
                }
            })
        }
    }

    const handlePhoneChange = (newPhoneNumber) => {
        if (newPhoneNumber.length < 11 || newPhoneNumber.length < phone.length) {
            setPhone(newPhoneNumber)
        }
    }

    const onSubmit = () => {
        const newContact = { ...accountContact, name, phoneTel: phone, email }
        if (!isUnvalidEmail && !isUnvalidPhone) {
            dispatch(AccountAction.updateContact(newContact)).then(() => {
                setEditMode(false)
            })
        }
    }

    if (!isLoaded) {
        return <LoadingCard />
    }

    return (
        <Grid2
            container
            size={12}
            alignContent='flex-start'
            sx={{
                height: '100%',
                overflowY: 'hidden',
                color: textColor,
            }}
        >
            <Grid2 container size={12} alignItems='center' flexWrap='nowrap' justifyContent='space-between' >
                <Grid2 sx={{ fontSize: '22px', lineHeight: '28px' }}>{i18n.account}</Grid2>
                {(accountUser.isAdmin === '1' || accountUser.metadata === '1') && (
                    <MainButton
                        noFullWidth
                        onClick={() => dispatch(push('/admin'))}
                        startIcon={<FolderOutlined sx={{ fontSize: '16px' }} />}
                        sx={{
                            margin: '0',
                            minHeight: '28px',
                            width: 'auto',
                            fontSize: '14px',
                            lineHeight: '20px',
                        }}
                    >
                        {i18n.selectFolder}
                    </MainButton>
                )}
            </Grid2>
            <Grid2 container size={12} sx={{ maxHeight: 'calc(100% - 2.5rem)', overflowY: 'auto', paddingTop: theme.spacing(3) }}>
                <LightCard
                    container
                    size={12}
                    justifyContent='space-between'
                    sx={{ padding: theme.spacing(3) }}
                >
                    <Grid2 size={12} container justifyContent='space-between' alignItems='center'>
                        <Grid2 fontSize={22}>{i18n.ids}</Grid2>
                        {!editMode && (
                            <TextButton noFullWidth={true} sx={{ margin: 0 }} onClick={() => setEditMode(true)} endIcon={<EditOutlined />}>
                                {i18n.changeInfos}
                            </TextButton>
                        )}
                    </Grid2>
                    <Grid2
                        size={12}
                        container
                        justifyContent='space-between'
                        sx={{ color: mainColor, paddingTop: theme.spacing(2) }}
                    >
                        <Grid2 size={5.9}>
                            <InputRow
                                noFullWidth
                                disabled={!editMode}
                                id='name'
                                label={i18n.name}
                                type='text'
                                value={name}
                                onChange={(event) => setName(event.target.value)}
                                variant='outlined'
                                onKeyDown={(e) => e.key === 'Enter' ? onSubmit() : null}
                                sx={{
                                    '& .MuiInputBase-input.Mui-disabled': {
                                        WebkitTextFillColor: mainColor,
                                    },
                                }}
                            />
                        </Grid2>
                        <Grid2 size={5.9} />
                        <Grid2 size={5.9}>
                            <InputRow
                                noFullWidth
                                disabled={!editMode}
                                id='mail'
                                label={i18n.mailAddress}
                                type='text'
                                value={email}
                                onChange={(event) => setEmail(event.target.value)}
                                variant='outlined'
                                onKeyDown={(e) => e.key === 'Enter' ? onSubmit() : null}
                                error={isUnvalidEmail}
                                helperText={isUnvalidEmail ? i18n.unvalidAddress : ''}
                                sx={{
                                    '& .MuiInputBase-input.Mui-disabled': {
                                        WebkitTextFillColor: mainColor,
                                    },
                                }}
                            />
                        </Grid2>
                        <Grid2 size={5.9}>
                            <InputRow
                                noFullWidth
                                disabled={!editMode}
                                id='tel'
                                label={i18n.phoneTel}
                                type='text'
                                value={!editMode ? formatPhone(phone) : phone}
                                onChange={(event) => handlePhoneChange(event.target.value)}
                                variant='outlined'
                                onKeyDown={(e) => e.key === 'Enter' ? onSubmit() : null}
                                error={isUnvalidPhone}
                                helperText={isUnvalidPhone ? i18n.unvalidPhone : ''}
                                sx={{
                                    '& .MuiInputBase-input.Mui-disabled': {
                                        WebkitTextFillColor: mainColor,
                                    },
                                }}
                            />
                        </Grid2>
                    </Grid2>
                    {editMode && (
                        <Grid2 container size={12} alignItems='center' justifyContent='flex-end'>
                            <Grid2 sx={{ paddingRight: theme.spacing(2) }}>
                                <TextButton sx={{ color: 'black', margin: 0 }} onClick={() => {
                                    setEditMode(false)
                                    setName(accountContact.name || '')
                                    setPhone(accountContact.phoneTel || '')
                                    setEmail(accountContact.email || '')
                                }}>
                                    {i18n.abandon}
                                </TextButton>
                            </Grid2>
                            <Grid2>
                                <MainButton onClick={onSubmit}>
                                    {i18n.saveChanges}
                                </MainButton>
                            </Grid2>
                        </Grid2>
                    )}
                </LightCard>
                <LightCard
                    container
                    size={12}
                    justifyContent='space-between'
                    sx={{ padding: theme.spacing(3), marginTop: theme.spacing(2) }}
                >
                    <Grid2 size={12} container justifyContent='space-between' alignItems='center'>
                        <Grid2 fontSize={22}>{i18n.password}</Grid2>
                        <TextButton noFullWidth={true} sx={{ margin: 0 }} onClick={toggleModal} endIcon={<EditOutlined />}>
                            {i18n.changePassword}
                        </TextButton>
                    </Grid2>
                </LightCard>
                <LightCard
                    container
                    size={12}
                    justifyContent='space-between'
                    sx={{ padding: theme.spacing(3), marginTop: theme.spacing(2) }}
                >
                    <Grid2 size={12} container justifyContent='space-between' alignItems='center'>
                        <Grid2 fontSize={22}>{i18n.CGU}</Grid2>
                        <TextButton noFullWidth={true} sx={{ margin: 0 }} onClick={() => dispatch(push('/myaccount/about'))} endIcon={<ArrowForwardIos />}>
                            {i18n.consultCGU}
                        </TextButton>
                    </Grid2>
                    <Grid2 container alignItems='center' fontSize={14} sx={{ paddingTop: theme.spacing(3) }}>
                        <Grid2 sx={{ paddingRight: theme.spacing(0.5) }}>{i18n.lastAccept} :</Grid2>
                        <Grid2 className='bold'>{getDate(dateValidCgu)}</Grid2>
                    </Grid2>
                </LightCard>
                <ModalUpdatePasswordDesktop
                    open={openModal}
                    toggleModal={toggleModal}
                    handleChangeMdp={(e) =>
                        setPassword(e.target.value)
                    }
                    handleChangeNewMdp={(e) =>
                        setNewPassword(e.target.value)
                    }
                    handleChangeConfirmation={(e) =>
                        setNewPasswordConfirmation(e.target.value)
                    }
                    onSavePassword={onSavePassword}
                />
            </Grid2>
        </Grid2>
    )
}

export default MyAccountDesktop
