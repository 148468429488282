import React, { useEffect, useMemo, useState } from 'react'
import { Grid2, Tooltip } from '@mui/material'
import packageJson from '../../../../package.json'
import defaultLogo from '../../../ressources/static/media/iryqua.png'
import { veryLightGrey, mainColor, menuColor, veryLightColor, darkRed, screenColor } from './styled/theme'
import { StyledBadgeDesktop } from './styled/badges'
import { AccountCircle, Assignment, AttachFile, BookmarkBorder, Folder, Home, LogoutRounded, Mail } from '@mui/icons-material'
import i18n from 'simple-react-i18n'
import { compact, isNil, orderBy, template, } from 'lodash'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { hasValue } from '../../../utils/NumberUtil'
import { ReactComponent as PointsLogo } from '../../../ressources/static/svg/Niveau7.svg'
import { push } from 'connected-react-router'
import HomeAction from '../../offline/actions/HomeAction'
import { getSetting, getSettingInt } from '../../../utils/FormUtils'
import ContactAction from '../contact/actions/ContactAction'
import { getLogin } from '../../../utils/UserUtils'
import useApplicationSetting from '../../../utils/customHooks/useApplicationSetting'
import { SITU_POINT_PUMP, SITU_PUMP_COUNTER } from '../referencials/materiels/constants/MaterielConstants'
import moment from 'moment'
import { CHRONICLES_CONSTANTS, DECLARATION_STATUS } from '../agri/constants/AgriConstants'
import { orderPointsList } from '../../../utils/ObjectUtils'
import { getDelaySinceLastEntry, getStateLabel } from '../../../utils/AgriUtils'
import { POINT_STATUS_OBJ } from '../referencials/installations/constants/InstallationsConstants'
import AccountAction from '../account/actions/AccountAction'
import AgriAction from '../agri/actions/AgriAction'
import ReferencialAction from '../referencials/actions/ReferencialAction'
import MaterielAction from '../referencials/materiels/actions/MaterielAction'
import InstallationsAction from '../referencials/installations/actions/InstallationsAction'
import CmsAction from '../cms/actions/CmsAction'
import CityAction from '../referencials/city/actions/CityAction'
import aquasysLogoPath from '../../../ressources/pictures/favicon.png'
import { getI18nTitleDataLength } from '../../../utils/StringUtil'
import { CMS_PATH } from '../../../conf/basepath'
import { Outlet, useLocation } from 'react-router'

const AppDesktop = () => {
    const {
        exploitation,
        accountUser,
        actualities,
        accountStatistics,
        declaration,
        listOfMessages,
        variousMateriels,
        installations,
        applicationSettings,
        survey,
        cmsEvents,
        contributor,
    } = useSelector(store => ({
        exploitation: store.AgriReducer.exploitation,
        accountUser: store.AccountReducer.accountUser,
        actualities: store.CmsReducer.actualities,
        accountStatistics: store.AccountReducer.accountStatistics,
        declaration: store.AgriReducer.declaration,
        listOfMessages: store.ContactReducer.listOfMessages,
        variousMateriels: store.MaterielReducer.variousMateriels,
        installations: store.InstallationsReducer.installations,
        applicationSettings: store.HomeReducer.applicationSettings,
        survey: store.AgriReducer.survey,
        cmsEvents: store.CmsReducer.cmsEvents,
        contributor: store.ReferencialReducer.contributor,
    }), shallowEqual)

    const materielsTypeForIndex = useApplicationSetting('materielsTypeForIndex', s => s?.split(',')?.map(id => parseInt(id)))

    const date = useMemo(() => moment(), [])

    const dispatch = useDispatch()

    const location = useLocation()

    const isAdmin = useMemo(() => accountUser.isAdmin === '1' || accountUser.metadata === '1', [accountUser])
    const surveyEndDate = useMemo(() => isAdmin ? survey?.adminEndDate : survey?.endDate, [survey, isAdmin])

    const navigate = (path) => {
        dispatch(push(path))
        window.scrollTo(0, 0)
    }

    const getAllMessages = () => {
        dispatch(ContactAction.getMessagesOf(getLogin()))
    }

    useEffect(() => {
        dispatch(AccountAction.fetchUser(getLogin()))
        dispatch(AgriAction.fetchDroughtSectorsRestrictions())
        dispatch(AgriAction.fetchExploitation())
        dispatch(AgriAction.fetchSurveys())
        dispatch(CityAction.fetchCities())
        dispatch(CmsAction.fetchCmsActualities())
        dispatch(CmsAction.fetchCmsByCategory(5))
        dispatch(CmsAction.fetchCMSEvents())
        dispatch(ContactAction.fetchContacts())
        dispatch(HomeAction.fetchApplicationSettings())
        dispatch(MaterielAction.fetchAllVariousMateriels())
        dispatch(MaterielAction.fetchAllVariousMatTypes())
        dispatch(MaterielAction.fetchVariousMatSituations())
        dispatch(MaterielAction.fetchMatEventsTypes())
        dispatch(ReferencialAction.fetchCodesSandre())
        dispatch(ReferencialAction.fetchContributors())
        dispatch(ReferencialAction.fetchCultures())
        dispatch(ReferencialAction.fetchCulturesFamilies())
        dispatch(ReferencialAction.fetchManagementUnits())
        dispatch(ReferencialAction.fetchManagementUnitsRestrictions())
        dispatch(ReferencialAction.fetchTanksTypes())
        dispatch(ReferencialAction.fetchWatersheds())
        dispatch(ReferencialAction.fetchWaterTurnsRestrictions())
        dispatch(ReferencialAction.fetchWaterTurnsSlots())
    }, [])

    const { idExploitation, operatorCode } = useMemo(() => {
        return {
            idExploitation: exploitation.idExploitation,
            operatorCode: exploitation.operatorCode,
        }
    }, [exploitation])

    useEffect(() => {
        if (idExploitation && !declaration.idDeclaration) {
            dispatch(AgriAction.fetchDeclarationByExploitationId(idExploitation))
        }
    }, [exploitation])

    useEffect(() => {
        if (idExploitation && !installations.length) {
            dispatch(InstallationsAction.fetchInstallationsByExploitationId(idExploitation))
        }
    }, [exploitation])

    useEffect(() => {
        if (declaration?.idDeclaration && (!survey?.idSurvey || (declaration?.idSurvey !== survey?.idSurvey))) {
            dispatch(AgriAction.fetchSurvey(declaration.idSurvey))
        }
    }, [declaration])

    useEffect(() => {
        const applicationName = applicationSettings?.find(({ parameter }) => parameter === 'applicationName') || {}
        if (applicationName.value) {
            document.title = applicationName.value
            $('#favicon').attr('href', getSetting(applicationSettings, 'applicationFavicon') || aquasysLogoPath)
        }
    }, [applicationSettings])

    useEffect(() => {
        if (idExploitation) {
            dispatch(AgriAction.fetchWaterTurnsExploitation(idExploitation))
            dispatch(AgriAction.fetchExploitationVolumes(idExploitation))
            dispatch(MaterielAction.fetchMatEventsByExploitation(idExploitation))
            dispatch(ReferencialAction.fetchContributor(operatorCode))
        }
    }, [exploitation])

    useEffect(() => {
        getAllMessages()
        const interval = setInterval(() => {
            getAllMessages()
        }, 30000)

        return () => clearInterval(interval)
    }, [])
    const [logoUrl, setLogoUrl] = useState(localStorage.getItem('IRYQUA_logoUrl'))

    useEffect(() => {
        dispatch(HomeAction.checkUrl(logoUrl, isValid => {
            if (isValid) return
            const cmsHomeId = getSettingInt(applicationSettings, 'iryquaCmsAccueil')
            const cmsHome = cmsEvents.find((c) => c.id === cmsHomeId)
            const url = cmsHome?.document?.length ? CMS_PATH + cmsHome.document[0]?.name : null
            setLogoUrl(url)
            localStorage.removeItem('IRYQUA_logoUrl')
            localStorage.setItem('IRYQUA_logoUrl', url)
        }))
    }, [localStorage, cmsEvents])

    const isActive = (path) => {
        return location.pathname.startsWith(path)
    }

    const getMaterial = (idVariousMaterial) => {
        return variousMateriels.find(m => m.id === idVariousMaterial)
    }

    const getSitusAndIdsVarious = (idInstallation) => {
        const pointPumps = (exploitation.link_materiel || []).filter((m) => m.siteType === SITU_POINT_PUMP && m.siteCode === idInstallation && (m.situationEndDate ? m.situationEndDate > date.valueOf() : true) && (m.situationDate ? m.situationDate < date.valueOf() : true)) || []
        const pointCounters = (exploitation.link_materiel || []).filter((m) => m.siteType === SITU_PUMP_COUNTER && pointPumps.find(({ idVarious }) => idVarious === m.siteCode) && (m.situationEndDate ? m.situationEndDate > date.valueOf() : true) && (m.situationDate ? m.situationDate < date.valueOf() : true)) || []
        const pumpsIds = pointPumps.map(({ idVarious }) => idVarious)
        const countersIds = pointCounters.map(({ idVarious }) => idVarious)
        return { pumpsIds, countersIds, pointPumps, pointCounters }
    }

    const getWarningCondition = (idInstallation) => {
        const { pumpsIds, countersIds, pointPumps, pointCounters } = getSitusAndIdsVarious(idInstallation)
        const ids = [...pumpsIds, ...countersIds]
        const pointMats = [...pointPumps, ...pointCounters]
        const materialsSeizableIndex = ids?.map(id => getMaterial(id))?.filter(m => materielsTypeForIndex?.includes(m?.materielType))
        if (materialsSeizableIndex?.length > 0) {
            const lastIndex = orderBy(exploitation.link_chronicles.filter((c) => materialsSeizableIndex.some(m => m.id ===c.idMat) && c.measureType === CHRONICLES_CONSTANTS.TYPE_INDEX), 'measureDate', 'desc')[0]
            if (lastIndex) {
                return { idMatLastChronicle: lastIndex.idMat, days: lastIndex.measureDate, important: true, estim: false }
            }
            const lastAffectIndex = orderBy(pointMats.filter(m => materialsSeizableIndex.some(mat => mat.id === m.idVarious)), 'situationDate', 'desc')[0]
            if (lastAffectIndex) {
                return { days: lastAffectIndex.situationDate, important: false }
            }
        }
        const lastEstim = orderBy(exploitation.link_chronicles.filter((c) => ids.includes(c.idMat) && c.measureType === CHRONICLES_CONSTANTS.TYPE_ESTIM), 'measureDate', 'desc')[0]
        if (lastEstim) {
            return { days: lastEstim.measureDate, important: true, estim: true }
        }
        return { days: null }
    }

    const getPoints = () => (
        orderPointsList(compact(exploitation?.link_samplings?.map((linkPoint) => {
            const point = installations.find((i) => i.id === linkPoint.idInstallation)
            if (point) {
                const { idMatLastChronicle, days, important, estim } = getWarningCondition(point.id)
                const { warnDate, warning } = days ?
                    getDelaySinceLastEntry(days, point)
                    :
                    { warnDate: null, warning: true }
                const stateCode = linkPoint.stateCode
                const pointFormatted = {
                    ...point,
                    days: warnDate,
                    idMatLastChronicle,
                    warning,
                    important: stateCode !== POINT_STATUS_OBJ.USED ? false : important,
                    estim,
                    stateCode,
                    stateLabel: getStateLabel(stateCode),
                }
                return pointFormatted
            }
            return null
        })))
    )
    const actionOnInstallation = getPoints().filter(p => p.warning && p.important).length

    const hasExploit = hasValue(exploitation?.idExploitation)
    const hasDeclaration = hasValue(declaration?.idDeclaration)
    const lastLogin = orderBy(accountStatistics.filter((s) => (s.eventType === 'LOGIN')), ['statisticDate'], ['desc'])[1]
    const actuNotSeen = useMemo(() => compact(actualities.map((a) => {
        if (lastLogin?.statisticDate < a.updateDate) {
            if (a.status === 1 || accountUser.isAdmin === '1') {
                return a
            }
        }
        return null
    })), [actualities, lastLogin])
    const messageNotSeen = useMemo(() => isNil(listOfMessages) ? compact(listOfMessages.map(m => {
        if (lastLogin?.statisticDate < m.updateDate) {
            if (m.updateLogin !== getLogin()) {
                return m
            }
        }
        return null
    })) : null, [listOfMessages, lastLogin])
    const actuToShow = orderBy(actuNotSeen, 'updateDate')
    const alertDecla = useMemo(() => ({
        day: moment(surveyEndDate).diff(date, 'days'),
        hour: moment(surveyEndDate).diff(date, 'hour') % 24,
    }), [surveyEndDate, date])

    const alertLabel = useMemo(() => {
        if (alertDecla.day) {
            return template(i18n.declarationTimeRemaining)({ time: `${alertDecla.day} ${getI18nTitleDataLength(i18n.day, i18n.days, alertDecla.day)}` })
        } else if (alertDecla.hour) {
            return template(i18n.declarationTimeRemaining)({ time: `${alertDecla.hour} ${getI18nTitleDataLength(i18n.hour, i18n.hours, alertDecla.hour)}` })
        }
        return template(i18n.haveUntilTimeToCompleteDeclaration)({ time: surveyEndDate?.format('hh:mm') })
    }, [alertDecla, alertDecla, surveyEndDate])

    const gridStyle = { padding: '4px 16px', width: '100%', borderRadius: '20px', display: 'flex', justifyContent: 'center', alignItems: 'center' }
    const iconStyle = { height: '18px', width: 'auto', color: mainColor }
    const labelStyle = { color: mainColor, fontSize: '18px', display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }
    const hasAlertDecla = useMemo(() => (accountUser && hasDeclaration && surveyEndDate && alertDecla.day <= 7 && alertDecla.day >= 0), [hasDeclaration, surveyEndDate, accountUser])

    return (
        <Grid2 container sx={{ backgroundColor: veryLightColor, padding: '2.5rem 2rem', height: '100vh', overflow: 'hidden' }}>
            <Grid2 container size={1.5} sx={{ height: '100%' }}>
                <Grid2 container direction='column' justifyContent='space-between' alignItems='center' sx={{ paddingRight: '2rem' }}>
                    <Grid2 container>
                        <Grid2 container direction='column' textAlign='center' width='100%' justifyContent='space-between'>
                            <Grid2>
                                <img src={logoUrl || defaultLogo} alt='' style={{ width: '100%', maxHeight: '70px' }} />
                            </Grid2>
                            <span style={{ fontSize: '10px' }}>v{packageJson.version}</span>
                            {exploitation?.codification && (
                                <p style={{ color: mainColor }}>
                                    <b>{exploitation.codification}</b><br />
                                    {contributor?.name && <span style={{ color: mainColor }}>{contributor.name}</span>}
                                </p>
                            )}
                            <Grid2 container direction='column' gap='8px' paddingTop='6rem'>
                                <Grid2 container className='clickable' width='100%' sx={{ ...gridStyle, backgroundColor: isActive('/home') ? menuColor : 'none' }} onClick={() => navigate('/home')} >
                                    <Grid2 size={2} >
                                        <StyledBadgeDesktop badgeContent={actuToShow.length} badgeColor={mainColor} >
                                            <Home sx={{ ...iconStyle }} />
                                        </StyledBadgeDesktop>
                                    </Grid2>
                                    <Grid2 size={10} sx={labelStyle}>
                                        {i18n.home}
                                    </Grid2>
                                </Grid2>
                                <Grid2 container className='clickable' width='100%' sx={{ ...gridStyle, backgroundColor: isActive('/installations') || isActive('/index') ? menuColor : 'none' }} onClick={hasExploit ? () => navigate('/installations') : () => {}} >
                                    <Grid2 size={2} >
                                        <StyledBadgeDesktop badgeContent={actionOnInstallation} badgeColor={darkRed} >
                                            <PointsLogo fill={hasExploit ? mainColor : veryLightGrey} style={{ ...iconStyle }} />
                                        </StyledBadgeDesktop>
                                    </Grid2>
                                    <Grid2 size={10} sx={{ ...labelStyle, color: hasExploit ? mainColor : veryLightGrey }}>
                                        {i18n.installations}
                                    </Grid2>
                                </Grid2>
                                <Tooltip title={!hasDeclaration ? i18n.noStatement : (hasAlertDecla && !isActive('/declaration') ? alertLabel : null)} enterDelay={600} placement='right' sx={{ maxWidth: 350 }}>
                                    <Grid2 container className={hasDeclaration ? 'clickable' : ''} sx={{ ...gridStyle, backgroundColor: isActive('/declaration') ? menuColor : 'none', width: '100%' }} onClick={hasDeclaration ? () => navigate(declaration.statusCode === DECLARATION_STATUS.NOT_START ? '/declaration/start' : '/declaration') : () => {}} >
                                        <Grid2 size={2}>
                                            <StyledBadgeDesktop badgeContent={'!'} badgeColor={darkRed} invisible={!hasAlertDecla}>
                                                <Assignment style={{ ...iconStyle, color: hasDeclaration ? mainColor : veryLightGrey }} />
                                            </StyledBadgeDesktop>
                                        </Grid2>
                                        <Grid2 size={10} sx={{ ...labelStyle, color: hasDeclaration ? mainColor : veryLightGrey }}>
                                            {i18n.declaration}
                                        </Grid2>
                                    </Grid2>
                                </Tooltip>
                                <Grid2 container className='clickable' width='100%' sx={{ ...gridStyle, backgroundColor: isActive('/message') ? menuColor : 'none' }} onClick={() => navigate('/message')} >
                                    <Grid2 size={2} >
                                        <StyledBadgeDesktop badgeContent={messageNotSeen?.length}>
                                            <Mail sx={{ ...iconStyle }} />
                                        </StyledBadgeDesktop>
                                    </Grid2>
                                    <Grid2 size={10} sx={labelStyle}>
                                        {i18n.messages}
                                    </Grid2>
                                </Grid2>
                                <Grid2 container className='clickable' width='100%' sx={{ ...gridStyle, backgroundColor: isActive('/dossier') ? menuColor : 'none' }} onClick={() => navigate('/dossier')}>
                                    <Grid2 size={2}>
                                        <Folder sx={{ ...iconStyle }} />
                                    </Grid2>
                                    <Grid2 size={10} sx={labelStyle}>
                                        {i18n.folder}
                                    </Grid2>
                                </Grid2>
                                <Grid2 container className='clickable' width='100%' sx={{ ...gridStyle, backgroundColor: isActive('/documents') ? menuColor : 'none' }} onClick={() => navigate('/documents')}>
                                    <Grid2 size={2}>
                                        <AttachFile sx={{ ...iconStyle }} />
                                    </Grid2>
                                    <Grid2 size={10} sx={labelStyle}>
                                        {i18n.documents}
                                    </Grid2>
                                </Grid2>
                            </Grid2>
                        </Grid2>
                    </Grid2>
                    <Grid2 container>
                        <Grid2 container direction='column' gap='8px' >
                            <Grid2 container className='clickable' width='100%' sx={{ ...gridStyle, backgroundColor: isActive('/tutoriel') ? menuColor : 'none' }} onClick={() => navigate('/tutoriel')}>
                                <Grid2 size={2}>
                                    <BookmarkBorder sx={{ ...iconStyle }} />
                                </Grid2>
                                <Grid2 size={10} sx={labelStyle}>
                                    {i18n.tuto}
                                </Grid2>
                            </Grid2>
                            <Grid2 container className='clickable' width='100%' sx={{ ...gridStyle, backgroundColor: isActive('/myaccount/preferences') ? menuColor : 'none' }} onClick={() => navigate('/myaccount/preferences')}>
                                <Grid2 size={2}>
                                    <AccountCircle sx={{ ...iconStyle }} />
                                </Grid2>
                                <Grid2 size={10} sx={labelStyle}>
                                    {i18n.account}
                                </Grid2>
                            </Grid2>
                            <Grid2 container className='clickable' width='100%' sx={{ ...gridStyle, '&:hover': { backgroundColor: menuColor } }} onClick={() => dispatch(HomeAction.logout())} >
                                <Grid2 size={2}>
                                    <LogoutRounded sx={{ ...iconStyle }} />
                                </Grid2>
                                <Grid2 size={10} sx={labelStyle}>
                                    {i18n.disconnect}
                                </Grid2>
                            </Grid2>
                        </Grid2>
                    </Grid2>
                </Grid2>
            </Grid2>
            <Grid2 container size={10.5} sx={{ height: '100%', backgroundColor: screenColor, borderRadius: '20px', padding: '2rem', overflowY: 'auto' }}>
                <Outlet />
            </Grid2>
        </Grid2>
    )
}

export default AppDesktop
