'use strict'
import fetch from 'isomorphic-fetch'
import i18n from 'simple-react-i18n'
import {
    RECEIVE_INSTALLATION,
    RECEIVE_INSTALLATIONS,
    RESET_INSTALLATION,
    RECEIVE_MAP_SITUATIONS,
    RECEIVE_MAP_SITUATION_STATS,
    RECEIVE_ALL_LINKED_STATIONS,
    RECEIVE_MAP_SITUATIONS_RESULTS,
} from '../constants/InstallationsConstants'
import ApplicationConf from '../../../../../conf/ApplicationConf'
import { aquaFetchV2, checkAuth, checkError, genericFetch, genericPromise, getAuthorization } from '../../../../../utils/ActionUtils'
import ToastrAction from '../../../components/toasters/ToastrAction'
import LogAction from '../../../../../utils/log/actions/LogAction'

const InstallationsAction = {
    // TODO

    updateInstallation(installation) {
        return () => {
            return fetch(ApplicationConf.installation.path(), {
                method: 'PUT',
                headers: getAuthorization(),
                body: JSON.stringify(installation),
            })
                .then(checkAuth)
                .then(checkError)
                .then(json => {
                    if (json.update >= 0) {
                        ToastrAction.success(i18n.elementUpdateSuccess)
                    } else {
                        throw new Error()
                    }
                }).catch((err) => {
                    LogAction.logError(`${i18n.updateError + i18n.installation} : ${err}`)
                    ToastrAction.error(i18n.updateError + i18n.installation)
                })
        }
    },

    receiveInstallationsByExploitations(installations) {
        return { type: RECEIVE_INSTALLATIONS, installations }
    },

    fetchInstallationsByExploitationId(id) {
        return (dispatch, getState) => {
            return aquaFetchV2(ApplicationConf.installation.getExploitationInstallations(id), {
                method: 'GET',
                headers: getAuthorization(),
            }, { defaultResult: getState().InstallationsReducer.installations })
                .then((json = []) => {
                    dispatch(InstallationsAction.receiveInstallationsByExploitations(json))
                })
                .catch((err) => {
                    LogAction.logError(`${i18n.fetchError + i18n.installations} : ${err}`)
                    ToastrAction.error(i18n.fetchError + i18n.installations)
                })
        }
    },

    receiveInstallation(installation) {
        return { type: RECEIVE_INSTALLATION, installation }
    },

    fetchInstallation(id) {
        return (dispatch, getState) => {
            return aquaFetchV2(ApplicationConf.installation.get(id), {
                method: 'GET',
                headers: getAuthorization(),
            }, { defaultResult: getState().InstallationsReducer.installations.find((i) => i.id === id) })
                .then((json = []) => {
                    dispatch(InstallationsAction.receiveInstallation(json))
                    return json
                })
                .catch((err) => {
                    LogAction.logError(`${i18n.fetchError + i18n.installation} : ${err}`)
                    ToastrAction.error(i18n.fetchError + i18n.installation)
                })
        }
    },

    promiseMapSituations(stationType) {
        return genericPromise(ApplicationConf.piezometer.mapSituation(stationType))
    },

    fetchMapSituations(stationType) {
        return genericFetch(InstallationsAction.promiseMapSituations(stationType), RECEIVE_MAP_SITUATIONS)
    },

    promiseMapSituationStationStats(stationType, mapId) {
        return genericPromise(ApplicationConf.piezometer.mapSituationStats(stationType, mapId))
    },

    fetchMapSituationsStationStats(stationType, mapId) {
        return genericFetch(InstallationsAction.promiseMapSituationStationStats(stationType, mapId), RECEIVE_MAP_SITUATION_STATS)
    },

    promiseMapSituationResults(stationType, mapSituation, date) {
        return genericPromise(ApplicationConf.piezometer.mapSituationResults(stationType, mapSituation.id, date), 'POST', mapSituation)
    },
    fetchMapSituationsResults(stationType, mapSituation, date) {
        return genericFetch(InstallationsAction.promiseMapSituationResults(stationType, mapSituation, date), RECEIVE_MAP_SITUATIONS_RESULTS)
    },

    promiseAllLinkedStations(ids, stationType) {
        return genericPromise(ApplicationConf.station.getStationsLink(ids, stationType), 'POST', { ids, stationType })
    },

    fetchAllLinkedStations(ids, stationType) {
        return genericFetch(InstallationsAction.promiseAllLinkedStations(ids, stationType), RECEIVE_ALL_LINKED_STATIONS)
    },

    resetInstallation() {
        return { type: RESET_INSTALLATION }
    },
}

export default InstallationsAction
