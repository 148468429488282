import {
    RECEIVE_ACCOUNT,
    RECEIVE_ACCOUNT_CONTACT,
    RECEIVE_ACCOUNT_HABILITATION,
    RECEIVE_ACCOUNT_STATISTICS,
    RECEIVE_ACCOUNT_USER_KEYFIGURES,
    RECEIVE_ACCOUNT_USER_SETTINGS,
    SAVED_USER,
} from '../constants/AccountConstants'
import DtoAccountHabilitation from '../dto/DtoAccountHabilitation'
import KeyFigureDto from '../dto/KeyFigureDto'
import DtoAccountSettings from '../dto/DtoAccountSettings'
import DtoAccountStatistic from '../dto/DtoAccountStatistic'
import DtoUser from '../dto/DtoUser'
import { FULL_RESET } from '../../../offline/constants/HomeConstants'
import DtoContact from '../../referencials/dto/DtoContact'

export const store = {
    accountUser: {},
    accountHabilitations: [],
    accountUserKeyFigures: [],
    accountUserSettings: [],
    accountStatistics: [],
    accountContact: {},
}

export function AccountReducer(state = {}, action) {
    switch (action.type) {
        case RECEIVE_ACCOUNT_CONTACT:
            return Object.assign({}, state, {
                contact: new DtoContact(action.contact),
            })
        case RECEIVE_ACCOUNT:
            return { ...state, accountUser: new DtoUser(action.accountUser) }
        case RECEIVE_ACCOUNT_HABILITATION:
            return {
                ...state,
                accountHabilitations: action.accountHabilitations.map(
                    (el) => new DtoAccountHabilitation(el),
                ),
            }
        case RECEIVE_ACCOUNT_USER_KEYFIGURES:
            return {
                ...state,
                accountUserKeyFigures: action.accountUserKeyFigures.map(
                    (el) => new KeyFigureDto(el),
                ),
            }
        case RECEIVE_ACCOUNT_USER_SETTINGS:
            return {
                ...state,
                accountUserSettings: action.accountUserSettings.map(
                    (el) => new DtoAccountSettings(el),
                ),
            }
        case RECEIVE_ACCOUNT_STATISTICS:
            return {
                ...state,
                accountStatistics: action.accountStatistics.map(
                    (el) => new DtoAccountStatistic(el),
                ),
            }
        case SAVED_USER:
            return {
                ...state,
                user: action.user
            }
        case FULL_RESET:
            return {
                ...store,
            }
        default:
            return state
    }
}
