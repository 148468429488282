import {
    RECEIVE_CONTACTS,
    RECEIVE_MESSAGES_OF,
} from '../constants/ContactConstants'
import DtoContact from '../../referencials/dto/DtoContact'
import { FULL_RESET } from '../../../offline/constants/HomeConstants'
export const store = {
    contacts: [],
    listOfMessages: [],
}
export function ContactReducer(state = {}, action) {
    switch (action.type) {
        case RECEIVE_CONTACTS:
            return Object.assign({}, state, {
                contacts: action.contacts.map((c) => new DtoContact(c)),
            })
        case RECEIVE_MESSAGES_OF:
            return {
                ...state,
                listOfMessages: action.listOfMessages,
            }
        case FULL_RESET:
            return {
                ...store,
            }
        default:
            return state
    }
}
