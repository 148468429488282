export default class DtoWaterTurnRestriction {
    constructor(obj) {
        this.id = obj.id // Option[Long],
        this.level = obj.level // Option[Int],
        this.label = obj.label // Option[String],
        this.description = obj.description // Option[String],
        this.comment = obj.comment // Option[String],
        this.color = obj.color // Option[String],
        this.entryFrequency = obj.entryFrequency // Option[Int],
        this.entryFrequencyDay = obj.entryFrequencyDay // Option[Int],
        this.percentRestriction = obj.percentRestriction // Option[Double],
    }
}