'use strict'
import {
    RECEIVE_MAT_EVENTS_EXPLOITATION,
    RECEIVE_MAT_EVENTS_TYPE,
    RECEIVE_ALL_VARIOUS_MATERIELS,
    RECEIVE_VARIOUS_MAT_SITUATIONS,
    RECEIVE_VARIOUS_MAT_TYPES,
} from '../constants/MaterielConstants'
import { FULL_RESET } from '../../../../offline/constants/HomeConstants'
import DtoMatEvent from '../dto/DtoMatEvent'
import DtoMatEventsType from '../dto/DtoMatEventsType'
import DtoVariousMateriel from '../dto/DtoVariousMateriel'
import DtoVariousMatSituation from '../dto/DtoVariousMatSituation'
import DtoVariousMatType from '../dto/DtoVariousMatType'

export const store = {
    matEventsExploitation: [],
    matEventsTypes: [],
    variousMateriels: [],
    variousMatSituations: [],
    variousMatTypes: [],
}

export function MaterielReducer(state = {}, action) {
    switch (action.type) {
        case RECEIVE_MAT_EVENTS_EXPLOITATION:
            return {
                ...state,
                matEventsExploitation: action.matEventsExploitation.map((e) => new DtoMatEvent(e)),
            }
        case RECEIVE_MAT_EVENTS_TYPE:
            return {
                ...state,
                matEventsTypes: action.matEventsTypes.map((t) => new DtoMatEventsType(t)),
            }
        case RECEIVE_ALL_VARIOUS_MATERIELS: // // à passer dans MaterielReducer
            return {
                ...state,
                variousMateriels: action.variousMateriels.map((m) => new DtoVariousMateriel(m)),
            }
        case RECEIVE_VARIOUS_MAT_SITUATIONS: // // à passer dans MaterielReducer
            return {
                ...state,
                variousMatSituations: action.variousMatSituations.map((s) => new DtoVariousMatSituation(s)),
            }
        case RECEIVE_VARIOUS_MAT_TYPES: // // à passer dans MaterielReducer
            return {
                ...state,
                variousMatTypes: action.variousMatTypes.map((s) => new DtoVariousMatType(s)),
            }
        case FULL_RESET:
            return {
                ...store,
            }
        default:
            return state
    }
}
