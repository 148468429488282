module.exports = {
    RECEIVE_MAT_EVENTS_EXPLOITATION: 'RECEIVE_MAT_EVENTS_EXPLOITATION',
    RECEIVE_MAT_EVENTS_TYPE: 'RECEIVE_MAT_EVENTS_TYPE',
    RECEIVE_ALL_VARIOUS_MATERIELS: 'RECEIVE_ALL_VARIOUS_MATERIELS',
    RECEIVE_VARIOUS_MAT_SITUATIONS: 'RECEIVE_VARIOUS_MAT_SITUATIONS',
    RECEIVE_VARIOUS_MAT_TYPES: 'RECEIVE_VARIOUS_MAT_TYPES',
    SITU_POINT_PUMP: 7,
    SITU_PUMP_COUNTER: 8,
    LINK_TYPE: {
        REPART_PUMP: 1,
        REPART_COUNTER: 2,
    },
}
