'use strict'
import DtoIntervenant from '../../referencials/dto/DtoIntervenant'
import {
    RECEIVE_DECLARATION,
    RECEIVE_DECLARATIONS_STATS_EXPLOITATION,
    RECEIVE_EXPLOITATION,
    RECEIVE_EXPLOITATIONS_DATA,
    RECEIVE_EXPLOITATION_VOLUMES,
    RECEIVE_OPERATOR,
    RECEIVE_OPERATORS,
    RECEIVE_DECLARATION_CONTRIBUTORS,
    RECEIVE_MODES_IRRIGATIONS,
    RECEIVE_SURVEYS,
    RECEIVE_WATERTURNS,
    RECEIVE_WATERTURNS_EXPLOITATION,
    RECEIVE_SURVEY,
    RECEIVE_DROUGHT_SECTORS_RESTRICTIONS,
} from '../constants/AgriConstants'
import DtoDeclaration from '../dto/enquete/DtoDeclaration'
import DtoExploitation from '../dto/exploitation/DtoExploitation'
import DtoIntervenantDeclaration from '../dto/enquete/DtoIntervenantDeclaration'
import DtoDeclarationWithStats from '../dto/enquete/DtoDeclarationWithStats'
import DtoDeclarationVolumes from '../dto/enquete/DtoDeclarationVolumes'
import DtoEnquete from '../dto/enquete/DtoEnquete'
import { FULL_RESET, RESET_AGRI } from '../../../offline/constants/HomeConstants'
import DtoWaterTurn from '../dto/DtoWaterTurn'
import DtoDroughtSectorRestriction from '../dto/DtoDroughtSectorRestriction'

export const store = {
    exploitation: {},
    exploitationVolumes: [],
    exploitations: [],
    declaration: {},
    declarationsExploitation: [],
    declarations: [],
    declarationContributors: [],
    enquete: {},
    operator: {},
    operators: [],
    modesIrrigations: [],
    survey: {},
    surveys: [],
    exploitationsExportFullData: [],
    allWaterTurns: [],
    exploitationWaterTurns: [],
    droughtSectorsRestrictions: [],
    rseau: {},
    surveyVolumesExploitation: [],
}

export function AgriReducer(state = {}, action) {
    switch (action.type) {
        case RECEIVE_WATERTURNS:
            return {
                ...state,
                allWaterTurns: action.waterTurns.map((w) => new DtoWaterTurn(w)),
            }
        case RECEIVE_WATERTURNS_EXPLOITATION:
            return {
                ...state,
                exploitationWaterTurns: action.waterTurns.map((w) => new DtoWaterTurn(w)),
            }
        case RECEIVE_EXPLOITATIONS_DATA:
            return {
                ...state,
                exploitationsExportFullData: action.exploitationsExportFullData,
            }
        case RECEIVE_DECLARATION:
            return {
                ...state,
                declaration: new DtoDeclaration(action.declaration),
            }
        case RECEIVE_DECLARATIONS_STATS_EXPLOITATION:
            return {
                ...state,
                declarationsExploitation: action.declarations.map((d) => new DtoDeclarationWithStats(d)),
            }
        case RECEIVE_DECLARATION_CONTRIBUTORS:
            return {
                ...state,
                declarationContributors: action.declarationContributors.map(
                    (c) => new DtoIntervenantDeclaration(c),
                ),
            }
        case RECEIVE_EXPLOITATION:
            return {
                ...state,
                exploitation: new DtoExploitation(action.exploitation || {}),
            }
        case RECEIVE_EXPLOITATION_VOLUMES:
            return {
                ...state,
                exploitationVolumes: action.exploitationVolumes.map((v) => new DtoDeclarationVolumes(v))
            }
        case RECEIVE_OPERATOR:
            return {
                ...state,
                operator: new DtoIntervenant(action.operator),
            }
        case RECEIVE_OPERATORS:
            return {
                ...state,
                operators: action.operators,
            }
        case RECEIVE_MODES_IRRIGATIONS:
            return {
                ...state,
                modesIrrigations: action.modesIrrigations,
            }
        case RECEIVE_SURVEYS:
            return {
                ...state,
                surveys: action.surveys.map((s) => new DtoEnquete(s)),
            }
        case RECEIVE_SURVEY:
            return {
                ...state,
                survey: new DtoEnquete(action.survey),
            }
        case RESET_AGRI:
            return {
                ...store,
                exploitationsExportFullData: state.exploitationsExportFullData,
            }
        case RECEIVE_DROUGHT_SECTORS_RESTRICTIONS:
            return {
                ...state,
                droughtSectorsRestrictions: action.droughtSectorsRestrictions.map((d) => new DtoDroughtSectorRestriction(d)),
            }
        case FULL_RESET:
            return {
                ...store,
            }
        default:
            return state
    }
}
