'use strict'
import fetch from 'isomorphic-fetch'
import i18n from 'simple-react-i18n'
import {
    RECEIVE_MAT_EVENTS_EXPLOITATION,
    RECEIVE_MAT_EVENTS_TYPE,
    RECEIVE_ALL_VARIOUS_MATERIELS,
    RECEIVE_VARIOUS_MAT_SITUATIONS,
    RECEIVE_VARIOUS_MAT_TYPES,
} from '../constants/MaterielConstants'
import ApplicationConf from '../../../../../conf/ApplicationConf'
import { aquaFetchV2, checkAuth, checkError, getAuthorization } from '../../../../../utils/ActionUtils'
import ToastrAction from '../../../components/toasters/ToastrAction'
import LogAction from '../../../../../utils/log/actions/LogAction'

const MaterielAction = {
    receiveMatEventsByExploitation(matEventsExploitation) {
        return { type: RECEIVE_MAT_EVENTS_EXPLOITATION, matEventsExploitation }
    },
    fetchMatEventsByExploitation(exploitationId) {
        return (dispatch, getState) => {
            return aquaFetchV2(ApplicationConf.materiel.eventsExploitation(exploitationId), {
                method: 'GET',
                headers: getAuthorization(),
            }, { defaultResult: getState().MaterielReducer.matEventsExploitation })
                .then((json = []) => {
                    dispatch(MaterielAction.receiveMatEventsByExploitation(json))
                })
                .catch((err) => {
                    LogAction.logError(`${i18n.fetchError + i18n.events} : ${err}`)
                    ToastrAction.error(i18n.fetchError + i18n.events)
                })
        }
    },

    receiveMatEventsTypes(matEventsTypes) {
        return { type: RECEIVE_MAT_EVENTS_TYPE, matEventsTypes }
    },
    fetchMatEventsTypes() {
        return (dispatch, getState) => {
            return aquaFetchV2(ApplicationConf.materiel.eventsTypes(), {
                method: 'GET',
                headers: getAuthorization(),
            }, { defaultResult: getState().MaterielReducer.matEventsTypes })
                .then((json = []) => {
                    dispatch(MaterielAction.receiveMatEventsTypes(json))
                })
                .catch((err) => {
                    LogAction.logError(`${i18n.fetchError + i18n.eventsTypes} : ${err}`)
                    ToastrAction.error(i18n.fetchError + i18n.eventsTypes)
                })
        }
    },

    createMatEvent(event) {
        return () => {
            return aquaFetchV2(ApplicationConf.materiel.events(), {
                method: 'POST',
                headers: getAuthorization(),
                body: JSON.stringify(event),
            })
                .then((json) => {
                    ToastrAction.success(i18n.eventAddWithSuccess)
                    return json.id || null
                })
                .catch((err) => {
                    LogAction.logError(`${i18n.createError + i18n.event} : ${err}`)
                    ToastrAction.error(i18n.createError + i18n.event)
                })
        }
    },

    updateVariousMateriel(variousMateriel, callback=() => {}) {
        return (dispatch) => {
            return fetch(ApplicationConf.materiel.variousMateriel(variousMateriel.id), {
                method: 'PUT',
                headers: getAuthorization(),
                body: JSON.stringify(variousMateriel),
            })
                .then(checkAuth)
                .then(checkError)
                .then(json => {
                    if (json.update >= 1) {
                        dispatch(MaterielAction.fetchAllVariousMateriels()).then(() => callback())
                        ToastrAction.success(i18n.elementUpdateSuccess)
                        return true
                    }
                    throw new Error()
                })
                .catch(err => {
                    const label = i18n.updateError + i18n.materiel
                    dispatch(LogAction.logError(`${label} ${err}`))
                    ToastrAction.error(label)
                })
        }
    },

    receiveAllVariousMateriels(variousMateriels) {
        return { type: RECEIVE_ALL_VARIOUS_MATERIELS, variousMateriels }
    },

    fetchAllVariousMateriels() {
        return (dispatch, getState) => {
            return aquaFetchV2(ApplicationConf.materiel.variousMateriels(), {
                method: 'GET',
                headers: getAuthorization(),
            }, { defaultResult: getState().MaterielReducer.variousMateriels })
                .then((json = []) => {
                    dispatch(MaterielAction.receiveAllVariousMateriels(json))
                })
                .catch((err) => {
                    LogAction.logError(`${i18n.fetchError + i18n.variousMateriels} : ${err}`)
                    ToastrAction.error(i18n.fetchError + i18n.variousMateriels)
                })
        }
    },

    receiveAllVariousMatTypes(variousMatTypes) {
        return { type: RECEIVE_VARIOUS_MAT_TYPES, variousMatTypes }
    },

    fetchAllVariousMatTypes() {
        return (dispatch, getState) => {
            return aquaFetchV2(ApplicationConf.materiel.variousMaterielTypes(), {
                method: 'GET',
                headers: getAuthorization(),
            }, { defaultResult: getState().MaterielReducer.variousMatTypes })
                .then((json = []) => {
                    dispatch(MaterielAction.receiveAllVariousMatTypes(json))
                })
                .catch((err) => {
                    LogAction.logError(`${i18n.fetchError + i18n.variousMatTypes} : ${err}`)
                    ToastrAction.error(i18n.fetchError + i18n.variousMatTypes)
                })
        }
    },

    receiveVariousMatSituations(variousMatSituations) {
        return { type: RECEIVE_VARIOUS_MAT_SITUATIONS, variousMatSituations }
    },

    fetchVariousMatSituations() {
        return (dispatch, getState) => {
            return aquaFetchV2(ApplicationConf.materiel.variousMaterielsLastSituations(), {
                method: 'GET',
                headers: getAuthorization(),
            }, { defaultResult: getState().MaterielReducer.variousMatSituations })
                .then((json = []) => {
                    dispatch(MaterielAction.receiveVariousMatSituations(json))
                })
                .catch((err) => {
                    LogAction.logError(`${i18n.fetchError + i18n.variousMateriels} : ${err}`)
                    ToastrAction.error(i18n.fetchError + i18n.variousMateriels)
                })
        }
    },
}

export default MaterielAction
