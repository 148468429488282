import { Grid2, TextField } from '@mui/material'
import React, { useState } from 'react'
import i18n from 'simple-react-i18n'
import { getDate } from '../../../utils/DateUtil'
import moment from 'moment'
import PropTypes from 'prop-types'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { MainButton } from './styled/buttons'
import LoadingCard from './cards/LoadingCard'
import SimpleSelect from './forms/SimpleSelect'
import MaterielAction from '../referencials/materiels/actions/MaterielAction'
import { DialogContentMUIDesktop, DialogContentTextMUI, DialogMUI, DialogTitleMUIv2 } from './styled/dialog'
import { instanceOf } from '../../../utils/StoreUtils'
import DtoInstallation from '../referencials/installations/dto/DtoInstallation'

const ModalEventEntry = ({
    installation,
    selectedMaterial,
    setSelectedMaterial,
    openModalNewEvent,
    setOpenModalNewEvent,
}) => {
    const [eventDate, setEventDate] = useState(getDate(moment().valueOf(), 'YYYY-MM-DD'))
    const [idType, setIdType] = useState()
    const [comment, setComment] = useState()

    const dispatch = useDispatch()

    const {
        exploitation,
        variousMatSituations,
        matEventsTypes,
    } = useSelector(store => ({
        exploitation: store.AgriReducer.exploitation,
        variousMatSituations: store.MaterielReducer.variousMatSituations,
        matEventsTypes: store.MaterielReducer.matEventsTypes,
    }), shallowEqual)

    const materiel = selectedMaterial

    const saveEvent = () => {
        dispatch(MaterielAction.createMatEvent({
            eventDate: new Date(eventDate).getTime(),
            idType,
            comment,
            materielType: 'divers',
            idMateriel: materiel.id,
        })).then(() => {
            dispatch(MaterielAction.fetchMatEventsByExploitation(exploitation.idExploitation)).then(() =>
                window.history.back()
            )
        })
    }

    if (variousMatSituations.length) {
        return (
            <DialogMUI open={openModalNewEvent}
                keepMounted
                onClose={() => {
                    setOpenModalNewEvent(false)
                    setSelectedMaterial({})
                }}
            >
                <DialogTitleMUIv2 style={{ padding: '0 0 1.5vh 0' }}
                    onClick={() => {
                        setOpenModalNewEvent(false)
                        setSelectedMaterial({})
                    }}
                >
                    {installation.name || i18n.pointPrelevement } - {installation.code}
                </DialogTitleMUIv2>
                <DialogContentMUIDesktop style={{ width: '100%' }}>
                    <DialogContentTextMUI style={{ alignSelf: 'flex-start', padding: 0 }}>
                        {i18n.counter} <b>{materiel?.reference ?? ''}</b>
                    </DialogContentTextMUI>
                    <SimpleSelect
                        id='idType'
                        required
                        noNullValue
                        label={i18n.eventType}
                        value={idType}
                        onChange={(v) => setIdType(v)}
                        integerValue
                        options={matEventsTypes}
                        keyValue='id'
                    />
                    <TextField
                        fullWidth
                        id='eventDate'
                        label={i18n.date}
                        type='date'
                        required
                        variant='outlined'
                        value={moment(eventDate).format('YYYY-MM-DD')}
                        inputProps={{
                            max: '9999-12-31'
                        }}
                        onChange={(e) => setEventDate(e.target.value)}
                    />
                    <TextField
                        fullWidth
                        id='comment'
                        type='text'
                        variant='outlined'
                        label={i18n.comment}
                        placeholder={i18n.comment}
                        value={comment}
                        onChange={(e) => setComment(e.target.value)}
                        multiline
                        rows={10}
                        rowsMax={Infinity}
                        InputProps={{ style: { minHeight: 'inherit', height: '100%', alignItems: 'flex-start' } }}
                        sx={{ marginBottom: '16px' }}
                    />
                    <Grid2 container size={12}>
                        <Grid2 container size={12} justifyContent='center'>
                            <MainButton onClick={saveEvent} disabled={!comment || !eventDate || !idType}>
                                {i18n.add}
                            </MainButton>
                        </Grid2>
                    </Grid2>
                </DialogContentMUIDesktop>
            </DialogMUI>
        )
    }
    return <LoadingCard />
}

ModalEventEntry.propTypes = {
    installation: instanceOf(DtoInstallation),
    selectedMaterial: PropTypes.shape({}),
    setSelectedMaterial: PropTypes.func,
    openModalNewEvent: PropTypes.bool,
    setOpenModalNewEvent: PropTypes.func,
}

export default ModalEventEntry